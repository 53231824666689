import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="onexed-theme"]',
	id: "onexed-theme",
	name: "Onexed theme",
	primitives: [
		{
			id: "fontFamily",
			name: "fontFamily",
			values: [
				{
					cssVar: "--font-2",
					value: "DomaineText, Times New Roman, serif",
				},
			],
		},
	],
};

export default theme;
