import { Theme } from "@griddo/core";

import defaultTheme from "./default";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="default-alt"]',
	id: "default-alt",
	name: "The default alternative theme",
	primitives: [
		...(defaultTheme.primitives as Array<Theme.Primitive>),

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#F1F1F1" },
				{ cssVar: "--bg-1", value: "#F1F1F1" },
				{ cssVar: "--bg-2", value: "#FFFFFF" },
				{ cssVar: "--bg-1-card-hover", value: "rgba(24, 24, 24, 0.2)" },
			],
		},
	],
};

export default subtheme;
