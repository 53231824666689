import accent from "./accent";
import accentAlt from "./accent-alt";
import base from "./base";
import defaultTheme from "./default";
import defaultAlt from "./default-alt";
import inverse from "./inverse";
import inverseAlt from "./inverse-alt";

const bundle = {
	...base,
	subthemes: [accent, accentAlt, defaultTheme, defaultAlt, inverse, inverseAlt],
};

export default bundle;
