import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="icade-theme"]',
	id: "icade-theme",
	name: "ICADE theme",
	primitives: [],
};

export default theme;
