import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="euef-theme"]',
	id: "euef-theme",
	name: "EUEF theme",
	primitives: [],
};

export default theme;
