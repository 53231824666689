import { Theme } from "@griddo/core";

import defaultSubtheme from "./default";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="accent-alt"]',
	id: "accent-alt",
	name: "The accent alternative theme",
	primitives: [
		...(defaultSubtheme.primitives as Array<Theme.Primitive>),

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#ffffff" },
				{ cssVar: "--bg-1", value: "#ffffff" },
				{ cssVar: "--bg-1-card-hover", value: "#F6F5EE" },
				{ cssVar: "--bg-2", value: "#AD2A52" },
			],
		},
	],
};

export default subtheme;
