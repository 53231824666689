import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="icai-theme"]',
	id: "icai-theme",
	name: "ICAI theme",
	primitives: [],
};

export default theme;
